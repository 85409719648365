<template>
  <div class="container">
    <li v-for="chart in chantList" :key="chart.projectId">

      <myCharts v-if="chart.prioritiesStatistics.length" class="echartItem" :title="'优先级'"
        :projectName="chart.projectName" :chartName="'chartPriority'+chart.projectId"
        :chartDate="chart.prioritiesStatistics"></myCharts>
      <div class="echartItem NoDate" v-if="!chart.prioritiesStatistics.length">
        暂无优先级数据
      </div>

      <myCharts v-if="chart.statusStatistics.length" class="echartItem" :title="'状态'"
        :chartName="'chartState'+chart.projectId" :chartDate="chart.statusStatistics"></myCharts>
      <div class="echartItem NoDate" v-if="!chart.statusStatistics.length">
        暂无状态数据
      </div>

      <myCharts v-if="chart.memberPriorities.length" class="echartItem" :title="'人员'"
        :chartName="'chartPerson'" :chartDate="chart.memberPriorities"></myCharts>
      <div class="echartItem NoDate" v-if="!chart.memberPriorities.length">
        暂无人员数据
      </div>
    </li>

    <div class="searchBtn">
      <el-button type="primary" @click="searching" :loading="isloading">
        {{!isloading ? '搜索当前项目': '查询中...'}}</el-button>
    </div>

    <!-- 分页器 -->
    <div class="pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="1" :page-sizes="[2, 5]" :page-size="100"
        layout="total, sizes, prev, pager, next, jumper" :total="dataTotal">
      </el-pagination>
    </div>

  </div>
</template>

<script>
import { myCharts } from "../components/parts";
import { POST_DEFECTCHART, POST_CHARTLIST } from "../request/api";
export default {
  data() {
    return {
      chantList: [],
      prioritiesStatistics: [], //优先级
      statusStatistics: [], //状态
      memberPriorities: [], //人员

      projectId: null,
      projectName: "",
      isloading: false,
      pageNumber: 1,
      pageSize: 2,
      dataTotal: 0,
    };
  },
  created() {
    this.getChart();
  },
  methods: {
    //获取图表数据
    getChart() {
      POST_CHARTLIST({
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      })
        .then(({ data: res }) => {
          if (res.resCode == 1) {
            this.chantList = res.data.list;
            this.dataTotal = res.data.total;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    searching() {
      this.projectId = window.sessionStorage.getItem("currentId");
      this.projectName = window.sessionStorage.getItem("currentlabel");
      const isExist = this.chantList.filter(
        //判断是否已展示选中的数据，减少不必要的请求
        (item) => item.projectId == this.projectId
      ).length;
      if (isExist) {
        this.$notify({
          title: "提示",
          message: "当前项目数据已展示",
          type: "warning",
        });
      } else {
        POST_DEFECTCHART({
          projectId: this.projectId,
          // projectName: this.projectName,
        })
          .then(({ data: res }) => {
            if (res.resCode == 1) {
              this.chantList = [];
              this.chantList.push(res.data);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    // 分页器
    handleSizeChange(val) {
      this.pageSize = val;
      this.getChart();
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.getChart();
    },
  },
  components: {
    myCharts,
  },
};
</script>

<style lang="less" scoped>
.container {
  display: flex;
  padding-bottom: 20px;
  flex-wrap: wrap;
  justify-content: flex-start;
  background-color: #f5f5f5;

  li {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    background-color: #f5f5f5;

    .echartItem {
      width: 32.5%;
      height: 364px;
      margin-left: 0.8%;
      margin-top: 20px;
      background-color: #fff;
    }

    .NoDate {
      font-size: 24px;
      text-align: center;
      line-height: 364px;
    }
  }

  .searchBtn {
    margin: 30px;
  }

  .pagination {
    margin: 80px auto;
  }
}
</style>